import React, { useEffect } from 'react';
import Script from 'next/script';
import { v4 as uuid } from 'uuid';
import { useRouter } from 'next/router';
import config from '../../../env.config';

function ScriptLoader() {
  const router = useRouter();

  useEffect(() => {
    //  Hotjar User Attr
    if (typeof window !== 'undefined') {
      window.hj =
        window.hj ||
        ((...args) => {
          (hj.q = hj.q || []).push(args); // eslint-disable-line
        });
      window.hj('identify', uuid(), {
        language: router.locale
      });
    }
  });

  const inputHotjarLanguage = () => {
    if (typeof window !== 'undefined') {
      window.hj =
        window.hj ||
        ((...args) => {
          (hj.q = hj.q || []).push(args); // eslint-disable-line
        });
      window.hj('identify', uuid(), {
        language: router.locale
      });
    }
  };

  return (
    <>
      <Script
        strategy="beforeInteractive"
        id="OPTIMIZELY_SCRIPT"
        src="https://cdn.optimizely.com/js/30513490040.js"
      />
      <Script
        src="https://hotmart-tracking-manager.hotmart.com/hotmart-tracking-manager.min.js"
        data-gtm-id="GTM-KQFMS3D"
      />
      <Script strategy="afterInteractive">{inputHotjarLanguage()}</Script>
      <Script strategy="afterInteractive" id="LAUNCHER">
        {`(function(l,a,u,n,c,h,e,r){l['HotmartLauncherObject']=c;l[c]=l[c]||function(){module = '';(l[c].q=l[c].q||[]).push(arguments)},l[c].l=1*new Date();h=a.createElement(u),e=a.getElementsByTagName(u)[0];h.async=1;h.src=n;e.parentNode.insertBefore(h,e)})(window,document,'script','${config.envConfig.ANALYTICS}/launcher.js','hot');hot('system','display');`}
      </Script>
      <link rel="preload" href="https://sso.hotmart.com" as="document" />
    </>
  );
}

export default React.memo(ScriptLoader);
