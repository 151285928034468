import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import Link, {  } from 'next/link';
import { useTranslation } from 'next-i18next';

import Icons from 'components/Icons';
import { sanitize } from 'utils/string';
import { isMobileDevice } from '@core/presentation/utils/device';
import { DropdownSide } from 'components/Dropdown/types';
import { jsonLdScriptProps } from 'react-schemaorg';

import '@hotmart/cosmos/dist/styles/utilities/flex/flex.css';
import { topicPerTopification, TOPIC_KEY, TOPIFICATION_KEY } from '@core/domain/constants/topification';
import { getTopificationRouter } from '@core/presentation/utils/topification';
import { Breakpoints, VisibleSubsCount } from '../../constants';

const Dropdown = dynamic(() => import('components/Dropdown'), { ssr: false });
const DropdownMenuItem = dynamic(() => import('components/Dropdown/MenuItem'), { ssr: false });
const DropdownMenuHeader = dynamic(() => import('components/Dropdown/MenuHeader'), { ssr: false });
const DropdownMenuDivider = dynamic(() => import('components/Dropdown/MenuDivider'), { ssr: false });
const DropdownEllipsisButton = dynamic(() => import('components/Dropdown/EllipsisButton'), { ssr: false });

type Props = {
  homeUrl: string,
  topification?: TOPIFICATION_KEY,
  topic?: TOPIC_KEY | null,
  title?: string
};

function Breadcrumb({
  homeUrl,
  topification,
  topic,
  title
} : Props) {
  const [subcategoryList, setSubcategoryList] = useState<TOPIC_KEY[]>([]);
  const [isMobile, setMobile] = useState(isMobileDevice);
  const [visibleSubsCount, setVisibleSubsCount] = useState(VisibleSubsCount.DESKTOP_WIDE);
  const { t, i18n } = useTranslation();
  const router = useRouter();

  const mountBreadcrumbList = () => {
    const home = t('breadcrumb.home');

    const items: { name: string, url?: string }[] = [];
    const finalBreadcrumbList = [];

    if (home) {
      items.push({
        name: home,
        url: homeUrl
      });
    }

    if (topification) {
      items.push({
        name: t(`topification.${topification}`),
        url: getTopificationRouter({ language: i18n.language as Language, topification })
      });
    }

    if (topic) {
      items.push({
        name: t(`topic.${topic}`),
        url: getTopificationRouter({ language: i18n.language as Language, topification, topic })
      });
    }

    if (title) {
      items.push({
        name: title
      });
    }

    items.forEach((item, index) => {
      finalBreadcrumbList.push({
        '@type': 'ListItem',
        position: index + 1,
        name: sanitize(item.name),
        ...(item.url ? { item: item.url } : {})
      });
    });

    return finalBreadcrumbList;
  };

  const loadSubcategoryList = (topificationActive: TOPIFICATION_KEY) => {
    const topics = topicPerTopification[topificationActive];
    if (topics.length === 0) return;

    setSubcategoryList(topics);
  };

  const mountTopics = () => {
    return (
      <div className='_d-flex'>
        {subcategoryList?.slice(0, visibleSubsCount).map((item) => {
          const topicLink = getTopificationRouter({ language: router.locale as Language, topification: topification!, topic: item });
          const isActive = item === topic;

          const content = (
            <span
              key={item}
              className={classnames('breadcrumbs__category-label _ml-7', {
                '_font-weight-bold': isActive
              })}
            >
              {t(`topic.${item}`)}
            </span>
          );

          if (isActive) return content;

          return (
            <Link className='breadcrumbs__category-link' key={item} href={topicLink}>
              {content}
            </Link>
          );
        })}
      </div>
    );
  };

  const mountTopifications = () => {
    const topificationLink = getTopificationRouter({ language: router.locale as Language, topification: topification! });
    const content = (
      <span className={classnames('breadcrumbs__category-label',
        {
          'breadcrumbs__category-label__ellipsis': !isMobile,
          '_font-weight-bold': !topic
        })}
      >
        {t(`topification.${topification}`)}
      </span>
    );

    return (
      <div className='breadcrumbs__section__categories'>
        {!topic ? content : (
          <Link href={topificationLink} className='breadcrumbs__category-link'>
            {content}
          </Link>
        )}
        {!isMobile && <Icons prefix="far" iconName="chevron-right" className="_h-4 _w-4 _ml-4" />}
        {mountTopics()}
      </div>
    );
  };

  useEffect(() => {
    if (topification) {
      loadSubcategoryList(topification);
    }

    const handleResize = () => {
      const screenWidth = document?.body?.getBoundingClientRect()?.width;
      const screenMobile = Breakpoints.MOBILE;
      const screenTablet = Breakpoints.TABLET;
      const screenDesktop = Breakpoints.DESKTOP;

      if (screenWidth <= screenMobile) {
        setMobile(true);
        setVisibleSubsCount(VisibleSubsCount.MOBILE);
      } else if (screenWidth > screenMobile) {
        setMobile(false);
      }

      if (screenWidth > screenMobile && screenWidth <= screenTablet) {
        setVisibleSubsCount(VisibleSubsCount.TABLET);
      } else if (screenWidth > screenTablet && screenWidth <= screenDesktop) {
        setVisibleSubsCount(VisibleSubsCount.DESKTOP);
      } else if (screenWidth > screenDesktop) {
        setVisibleSubsCount(VisibleSubsCount.DESKTOP_WIDE);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize, { passive: true });

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const shouldShowDropdown = (subcategoryList?.length > visibleSubsCount) || isMobile;

  return (
    <>
      <script
        {...jsonLdScriptProps({
          '@context': 'https://schema.org',
          '@graph': [
            {
              '@type': 'Organization',
              name: 'Hotmart Marketplace',
              url: homeUrl,
              logo: 'https://hotmart.com/s/static/images/logo-hm.svg'
            },
            {
              '@type': 'BreadcrumbList',
              itemListElement: mountBreadcrumbList()
            }
          ]
        })}
      />
      <div className="breadcrumbs" role="navigation">
        <div className='_d-flex _align-items-center page-container--container'>
          <button type="button" onClick={() => router.back()}>
            <div className="breadcrumbs__section short">
              <div className="_h-4 _w-4 _text-gray-900">
                <Icons prefix="fal" iconName="arrow-left" />
              </div>
              <span className="_ml-4">
                {t('breadcrumb.back')}
              </span>
            </div>
          </button>
          {!isMobile && (
            <Link href={homeUrl}>
              <div className="breadcrumbs__section home-section">
                <div className="_h-4 _w-4 _text-gray-900">
                  <Icons prefix="fal" iconName="house" />
                </div>
                <span className="_ml-4">
                  {t('breadcrumb.home')}
                </span>
              </div>
            </Link>
          )}
          <div className="breadcrumbs__section _w-full _justify-content-between">
            {title && (
              <span className="_font-weight-bold">
                {title}
              </span>
            )}
            {topification && mountTopifications()}
            {shouldShowDropdown && (
              <div className="_pr-3">
                <Dropdown side={DropdownSide.RIGHT}>
                  <DropdownEllipsisButton className="dropdown-menu" aria-label={`${t('search_page.category_all')} ${t('search_page.category_title')}`} />
                  {subcategoryList?.length > 0 && (
                    <>
                      <DropdownMenuHeader title={t('season.filters.subcategories')} />
                      {subcategoryList
                        .slice(visibleSubsCount, subcategoryList.length)
                        .map((item) => {
                          const topicLink = getTopificationRouter({ language: router.locale as Language, topification: topification!, topic: item });
                          const isActive = item === topic;

                          return (
                            <DropdownMenuItem selected={isActive} key={item} onClick={() => router.push(topicLink)}>
                              <span>
                                {t(`topic.${item}`)}
                              </span>
                            </DropdownMenuItem>
                          );
                        })}
                      {isMobile && <DropdownMenuDivider />}
                    </>
                  )}
                  {isMobile && (
                    <>
                      <DropdownMenuHeader title={t('category.general.name')} />
                      <DropdownMenuItem>
                        <a href={homeUrl}>
                          <div className="_d-flex">
                            <div className="_h-4 _w-4 _text-gray-900">
                              <Icons prefix="fal" iconName="house" />
                            </div>
                            <span className="_ml-4">
                              {t('breadcrumb.home')}
                            </span>
                          </div>
                        </a>
                      </DropdownMenuItem>
                    </>
                  )}
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Breadcrumb;
